// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth } from "firebase/auth";

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDm69AAgXuJhZGlmF9NWOVR4iGF50iKJ1M",
    authDomain: "ashoka-c4782.firebaseapp.com",
    projectId: "ashoka-c4782",
    storageBucket: "ashoka-c4782.appspot.com",
    messagingSenderId: "844567080321",
    appId: "1:844567080321:web:b5988460ff4af531318c80",
    measurementId: "G-XJ1Q6HRNGH"
  };

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export const auth = getAuth(firebase)
