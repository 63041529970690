import logo from "./logo.svg"
import "./App.css"
import Form from "./components/form"
import Update from "./components/update"
import Report from "./components/report"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import SignIn from "./components/signin"
import {auth } from "./firebase/config"
import ResponsiveAppBar from "./components/dashboard"

function App() {
 
  console.log(auth)
  return (
    <div style={{ textAlign: "center" }}>
     
     
      <Router>
      {auth.currentUser?(
        <ResponsiveAppBar/>
      ):""}
      
      <img src="ashoka.png" className="logo"/>
        <Routes>
          <Route path="/" element={auth.currentUser?<Form />:<SignIn/>}></Route>
          <Route path="/update" element={auth.currentUser && auth.currentUser.email === "amanraheja30@gmail.com"?<Update />:<SignIn/>}></Route>
          <Route path="/report" element={auth.currentUser && auth.currentUser.email === "amanraheja30@gmail.com"?<Report />:<SignIn/>}></Route>
        </Routes>
      </Router>
      
    </div>
  )
}

export default App
