import React, { useState, useEffect } from "react"
import {
  Card,
  TextField,
  Button,
  TextareaAutosize,
  Autocomplete,
  Snackbar,
} from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import sanityClient from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

const client = sanityClient({
  projectId: "3g2qblp5",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: false,
  token: process.env.REACT_APP_SANITY,
})

let bill_series = await client.fetch(`*[_type == "billseries"]`)

// console.log("bill_series", bill_series.map(item => item.name))

let bill_series_rev = bill_series.map(item => item.name)

const query = `*[_type == "salesman"]`

let salesman = []

const salesman_response = await client.fetch(query)

salesman_response.map((item) => {
  salesman.push(item.name)
})

const achievedslab = ["MOP", "LP", "SP", "FIX", "ASP"]

let products = []

const products_response = await client.fetch(
  `*[_type == "productlist"]{product, brand}`
)

products_response.map((item) => {
  products.push(item.product)
})

products = [...new Set(products)]

// const sales_response = await client.delete(
//   {query:`*[_type == "sales" && _createdAt < '2024-01-01']| order(_createdAt asc)`}
// )

// console.log("sales_response",sales_response)

const Form = () => {
  const [open, setOpen] = useState(false)
  const [brand, setBrand] = useState("")
  const [product, setProduct] = useState("")
  const [model, setModel] = useState("")
  const [models, setModels] = useState([])
  const [brands, setBrands] = useState([])
  const [mop, setMOP] = useState({
    mop: "",
    lp: "",
    sp: "",
    mop_incentive: "",
    lp_incentive: "",
    sp_incentive: "",
  })

  useEffect(() => {
    setMOP({ mop: "", lp: "", sp: "" })
    const data = async () => {
      const model_response = await client.fetch(
        `*[_type == "productlist" && name == '${model}']{mop,lp,sp, mop_incentive, lp_incentive, sp_incentive}`
      )

      if (model_response.length !== 0) {
        setMOP({
          mop: model_response[0].mop,
          lp: model_response[0].lp,
          sp: model_response[0].sp,
          mop_incentive: model_response[0].mop_incentive,
          lp_incentive: model_response[0].lp_incentive,
          sp_incentive: model_response[0].sp_incentive,
        })
      } else {
        setMOP({ mop: "", lp: "", sp: "" })
      }
    }
    data()
  }, [model])

  useEffect(() => {
    const data = async () => {
      let brands_rev = []
      const brand_response = await client.fetch(
        `*[_type == "productlist" && product == '${product}']{brand}`
      )
      brand_response.map((item) => {
        brands_rev.push(item.brand)
      })

      brands_rev = [...new Set(brands_rev)]
      setBrands(brands_rev)
    }
    data()
  }, [product])

  useEffect(() => {
    const data = async () => {
      let models_rev = []
      const model_response = await client.fetch(
        `*[_type == "productlist" && brand == '${brand}' && product == '${product}']`
      )

      model_response.map((item) => {
        models_rev.push(item.name)
      })
      setModels(models_rev)
    }
    data()
  }, [brand, product])
  const schema = Yup.object({
    bill_series: Yup.string().required("Please fill bill series"),
    bill_no: Yup.string().required("Please fill bill no"),
    sales_man: Yup.string().required("Please fill your name"),
    // achieved_slab: Yup.string().required("Please fill achieved slab"),
    // achieved_slab_amount: Yup.string().required(
    //   "Please fill achieved slab amount"
    // ),
    product: Yup.string().required("Please fill the product you sold"),
    brand: Yup.string().required("Please fill the brand"),
    model_no: Yup.string().required("Please fill model no"),
    // item_mop: Yup.string().required("Please fill item mop amount"),
    sale_amount: Yup.number().required("Please fill sale amount").min(1),
    exchange_product: Yup.string().required("Please fill exchange product"),
    bill_signedby: Yup.string().required("Please fill who signed the bill"),
    quantity: Yup.number().required("Please fill quantity").min(1).integer(),
  })
  const formik = useFormik({
    initialValues: {
      bill_series: "",
      bill_no: "",
      sales_man: "",
      achieved_slab: "",
      achieved_slab_amount: "",
      product: "",
      brand: "",
      model_no: "",
      item_mop: "",
      item_sp: "",
      item_lp: "",
      sale_amount: "",
      exchange_product: "",
      bill_signedby: "",
      quantity: "",
    },
    onSubmit: async (values, helpers) => {
      try {
        console.log(values)
        let product = await client.fetch(
          `*[_type == "productlist" && name == '${values.model_no}']`
        )
        let length = await client.fetch(`*[_type == "sales"]| order(_createdAt desc)[0]`)
        console.log("length", length)
        values.serial_no = String(parseInt(length.serial_no) + 1)
        console.log(product)
        if (product.length !== 0) {
          values.item_mop = product[0].mop
          values.item_sp = product[0].sp
          values.item_lp = product[0].lp
          if(product[0].nlc){
            values.nlc = product[0].nlc
          }
          values.achieved_slab = "None"
          values.achieved_slab_amount = String(0)

          if (parseFloat(values.sale_amount) >= parseFloat(product[0].lp)) {
            values.achieved_slab = "LP"
            values.achieved_slab_amount = product[0].lp_incentive
          }
          if (parseFloat(values.sale_amount) >= parseFloat(product[0].sp)) {
            values.achieved_slab = "SP"
            values.achieved_slab_amount = product[0].sp_incentive
          }
          if (parseFloat(values.sale_amount) >= parseFloat(product[0].mop)) {
            values.achieved_slab = "MOP"
            values.achieved_slab_amount = product[0].mop_incentive
          }
          values.sale_amount = String(values.sale_amount)
          values.quantity = String(values.quantity)

          let mutation = {
            _id: formik.values.bill_no,
            _type: "sales",
            ...values,
          }

          let mutations = [
            {
              create: mutation,
            },
          ]

          fetch(
            "https://3g2qblp5.api.sanity.io/v2021-10-21/data/mutate/production",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_SANITY}`,
              },
              body: JSON.stringify({ mutations }),
            }
          )
            .then((response) => response.json())
            .then((response) => {
              console.log(response)
              if (response.transactionId) {
                setOpen(true)
                formik.resetForm()
                setModel("")
              }
              if (
                response.error &&
                response.error.items[0].error.type ===
                  "documentAlreadyExistsError"
              ) {
                alert(
                  "This Bill No is already Submitted. Please try with another Bill No."
                )
              }
            })
            .catch((err) => console.log(err))
        }
      } catch (error) {
        helpers.setErrors({ submit: error.message })
        alert("Form is not complete")
      }
    },
    validationSchema: schema,
  })
  return (
    <div className="contactformcontainer">
      <h1 style={{ fontFamily: "'Libre Baskerville', serif",}}>MOP INCENTIVE FORM</h1>
      {/* <p>
        {" "}
        If you have any queries, please contact us at
        contact@digitalmitra.co.nz.
      </p> */}
      <Card className="contactform">
        <Autocomplete
          disablePortal
          options={bill_series_rev}
          // sx={{ width: 300 }}
          value={formik.values.bill_series}
          onChange={(event, item) => {
            formik.setValues({
              bill_series: item,
              bill_no: formik.values.bill_no,
              sales_man: formik.values.sales_man,
              achieved_slab: formik.values.achieved_slab,
              achieved_slab_amount: formik.values.achieved_slab_amount,
              product: formik.values.product,
              brand: formik.values.brand,
              model_no: formik.values.model_no,
              item_mop: formik.values.item_mop,
              item_sp: formik.values.item_sp,
              item_lp: formik.values.item_lp,
              sale_amount: formik.values.sale_amount,
              exchange_product: formik.values.exchange_product,
              bill_signedby: formik.values.bill_signedby,
              quantity: formik.values.quantity,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Bill Series"
              error={formik.errors.bill_series ? true : false}
              helperText={formik.errors.bill_series}
            />
          )}
        />
        <TextField
          fullWidth
          label="Bill No"
          name="bill_no"
          value={formik.values.bill_no}
          style={{ width: "100%", marginTop: "30px" }}
          error={formik.errors.bill_no ? true : false}
          helperText={formik.errors.bill_no}
          onChange={formik.handleChange}
        ></TextField>
        <Autocomplete
          disablePortal
          options={salesman}
          value={formik.values.sales_man}
          style={{ width: "100%", marginTop: "30px" }}
          // sx={{ width: 300 }}
          onChange={(event, item) => {
            formik.setValues({
              bill_series: formik.values.bill_series,
              bill_no: formik.values.bill_no,
              sales_man: item,
              achieved_slab: formik.values.achieved_slab,
              achieved_slab_amount: formik.values.achieved_slab_amount,
              product: formik.values.product,
              brand: formik.values.brand,
              model_no: formik.values.model_no,
              item_mop: formik.values.item_mop,
              item_sp: formik.values.item_sp,
              item_lp: formik.values.item_lp,
              sale_amount: formik.values.sale_amount,
              exchange_product: formik.values.exchange_product,
              bill_signedby: formik.values.bill_signedby,
              quantity: formik.values.quantity,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sales Man"
              error={formik.errors.sales_man ? true : false}
              helperText={formik.errors.sales_man}
            />
          )}
        />

        <Autocomplete
          disablePortal
          options={products}
          style={{ width: "100%", marginTop: "30px" }}
          value={formik.values.product}
          // sx={{ width: 300 }}
          onChange={(event, item) => {
            setProduct(item)
            formik.setValues({
              bill_series: formik.values.bill_series,
              bill_no: formik.values.bill_no,
              sales_man: formik.values.sales_man,
              achieved_slab: formik.values.achieved_slab,
              achieved_slab_amount: formik.values.achieved_slab_amount,
              product: item,
              brand: formik.values.brand,
              model_no: formik.values.model_no,
              item_mop: formik.values.item_mop,
              item_sp: formik.values.item_sp,
              item_lp: formik.values.item_lp,
              sale_amount: formik.values.sale_amount,
              exchange_product: formik.values.exchange_product,
              bill_signedby: formik.values.bill_signedby,
              quantity: formik.values.quantity,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product"
              error={formik.errors.product ? true : false}
              helperText={formik.errors.product}
            />
          )}
        />
        <Autocomplete
          disablePortal
          options={brands}
          style={{ width: "100%", marginTop: "30px" }}
          value={formik.values.brand}
          // sx={{ width: 300 }}
          onChange={(event, item) => {
            setBrand(item)
            formik.setValues({
              bill_series: formik.values.bill_series,
              bill_no: formik.values.bill_no,
              sales_man: formik.values.sales_man,
              achieved_slab: formik.values.achieved_slab,
              achieved_slab_amount: formik.values.achieved_slab_amount,
              product: formik.values.product,
              brand: item,
              model_no: formik.values.model_no,
              item_mop: formik.values.item_mop,
              item_sp: formik.values.item_sp,
              item_lp: formik.values.item_lp,
              sale_amount: formik.values.sale_amount,
              exchange_product: formik.values.exchange_product,
              bill_signedby: formik.values.bill_signedby,
              quantity: formik.values.quantity,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Brand"
              error={formik.errors.brand ? true : false}
              helperText={formik.errors.brand}
            />
          )}
        />
        <Autocomplete
          disablePortal
          options={models}
          style={{ width: "100%", marginTop: "30px" }}
          value={formik.values.model_no}
          // sx={{ width: 300 }}
          onChange={(event, item) => {
            formik.setValues({
              bill_series: formik.values.bill_series,
              bill_no: formik.values.bill_no,
              sales_man: formik.values.sales_man,
              achieved_slab: formik.values.achieved_slab,
              achieved_slab_amount: formik.values.achieved_slab_amount,
              product: formik.values.product,
              brand: formik.values.brand,
              model_no: item,
              item_mop: formik.values.item_mop,
              item_sp: formik.values.item_sp,
              item_lp: formik.values.item_lp,
              sale_amount: formik.values.sale_amount,
              exchange_product: formik.values.exchange_product,
              bill_signedby: formik.values.bill_signedby,
              quantity: formik.values.quantity,
            })
            setModel(item)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Model"
              error={formik.errors.model_no ? true : false}
              helperText={formik.errors.model_no}
            />
          )}
        />
        {model !== "" && (
          <>
            <div style={{ textAlign: "left", fontStyle: "italic" }}>
              <strong>MOP:</strong>
              {mop.mop}, <strong>SP:</strong>
              {mop.sp}, <strong>LP:</strong>
              {mop.lp}
            </div>
            <div style={{ textAlign: "left", fontStyle: "italic" }}>
              <strong>MOP Inc:</strong>
              {mop.mop_incentive}, <strong>SP Inc:</strong>
              {mop.sp_incentive}, <strong>LP:</strong>
              {mop.lp_incentive}
            </div>
          </>
        )}

        {/* <TextField
          type="number"
          label="Qty"
          inputProps={{
            min: 0,
            max: 100,
            step: 1,
          }}
          value={formik.values.quantity}
          name="quantity"
          style={{ width: "100%", marginTop: "30px" }}
          error={formik.errors.quantity ? true : false}
          helperText={formik.errors.quantity}
          onChange={formik.handleChange}
        ></TextField> */}

        <Autocomplete
          disablePortal
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          style={{ width: "100%", marginTop: "30px" }}
          value={formik.values.quantity}
          // sx={{ width: 300 }}
          onChange={(event, item) => {
            formik.setValues({
              bill_series: formik.values.bill_series,
              bill_no: formik.values.bill_no,
              sales_man: formik.values.sales_man,
              achieved_slab: formik.values.achieved_slab,
              achieved_slab_amount: formik.values.achieved_slab_amount,
              product: formik.values.product,
              brand: formik.values.brand,
              model_no: formik.values.model_no,
              item_mop: formik.values.item_mop,
              item_sp: formik.values.item_sp,
              item_lp: formik.values.item_lp,
              sale_amount: formik.values.sale_amount,
              exchange_product: formik.values.exchange_product,
              bill_signedby: formik.values.bill_signedby,
              quantity: item,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Qty"
              name="quantity"
              error={formik.errors.quantity ? true : false}
              helperText={formik.errors.quantity}
            />
          )}
        />

        <TextField
          type="number"
          fullWidth
          label="Item Sale Amount"
          value={formik.values.sale_amount}
          name="sale_amount"
          style={{ width: "100%", marginTop: "30px" }}
          error={formik.errors.sale_amount ? true : false}
          helperText={formik.errors.sale_amount}
          onChange={formik.handleChange}
        ></TextField>
        <Autocomplete
          disablePortal
          options={["Yes", "No"]}
          style={{ width: "100%", marginTop: "30px" }}
          value={formik.values.exchange_product}
          // sx={{ width: 300 }}
          onChange={(event, item) => {
            formik.setValues({
              bill_series: formik.values.bill_series,
              bill_no: formik.values.bill_no,
              sales_man: formik.values.sales_man,
              achieved_slab: formik.values.achieved_slab,
              achieved_slab_amount: formik.values.achieved_slab_amount,
              product: formik.values.product,
              brand: formik.values.brand,
              model_no: formik.values.model_no,
              item_mop: formik.values.item_mop,
              item_sp: formik.values.item_sp,
              item_lp: formik.values.item_lp,
              sale_amount: formik.values.sale_amount,
              exchange_product: item,
              bill_signedby: formik.values.bill_signedby,
              quantity: formik.values.quantity,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Exchange Product"
              name="exchange_product"
              error={formik.errors.exchange_product ? true : false}
              helperText={formik.errors.exchange_product}
            />
          )}
        />
        <Autocomplete
          disablePortal
          options={[
            "Aman Sir",
            "Tarun Sir",
            "Ashok Sir",
            "Gaurav Sir",
            "Himashu Sir",
          ]}
          style={{ width: "100%", marginTop: "30px" }}
          value={formik.values.bill_signedby}
          // sx={{ width: 300 }}
          onChange={(event, item) => {
            formik.setValues({
              bill_series: formik.values.bill_series,
              bill_no: formik.values.bill_no,
              sales_man: formik.values.sales_man,
              achieved_slab: formik.values.achieved_slab,
              achieved_slab_amount: formik.values.achieved_slab_amount,
              product: formik.values.product,
              brand: formik.values.brand,
              model_no: formik.values.model_no,
              item_mop: formik.values.item_mop,
              item_sp: formik.values.item_sp,
              item_lp: formik.values.item_lp,
              sale_amount: formik.values.sale_amount,
              exchange_product: formik.values.exchange_product,
              bill_signedby: item,
              quantity: formik.values.quantity,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Bill Signed By"
              name="bill_signedby"
              error={formik.errors.bill_signedby ? true : false}
              helperText={formik.errors.bill_signedby}
            />
          )}
        />
        <div className="buttons">
          <Button
            type="submit"
            style={{
              marginTop: "30px",
              backgroundColor: "black",
              color: "white",
              width: "150px",
              fontFamily: "'Libre Baskerville', serif",
              fontSize:"20px",
              borderRadius: "40%"
            }}
            
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={10000}
          onClose={() => setOpen(false)}
          message="Thanks for submitting the form"
          ContentProps={{
            sx: {
              background: "green",
            },
          }}
          // action={action}
        />
      </Card>
    </div>
  )
}

export default Form
