import React, { useEffect, useState } from "react"
import { TextField, Button, Snackbar, CircularProgress } from "@mui/material"
import { parse } from "papaparse"
import readXlsxFile from "read-excel-file"
import sanityClient from "@sanity/client"
import Data from "./data"
import DeleteModal from "./deletemodal"

const Update = () => {
  const [open, setOpen] = useState(false)
  const [openmodal, setOpenModal] = useState(false)
  const [opendelete, setOpenDelete] = useState(false)
  const [productlist, setProductList] = useState({
    productlist: [],
    show: false,
  })
  const [loading, setLoading] = useState(false)
  const [selectedrows, setSelectedRows] = React.useState([])

  const client = sanityClient({
    projectId: "3g2qblp5",
    dataset: "production",
    apiVersion: "2021-10-21",
    useCdn: false,
    token: process.env.REACT_APP_SANITY,
  })

  const handleLoading = (value) => {
    setLoading(value)
  }

  const handleShowProductList = (value) => {
    const data = async () => {
      const query = `*[_type == "productlist"]`
      const productlist_response = await client.fetch(query)
      setProductList({ productlist: productlist_response, show: true })
    }
    data()
  }

  console.log("productlist", productlist)
  console.log("loading", loading)

  const handleDeleteSelectedRows = () => {
    console.log(selectedrows)
    let mutations = {
      mutations: [
        {
          delete: {
            query: `*[_type == 'productlist' && _id in ['${selectedrows.join("','")}']]`,
          },
        },
      ],
    }
    console.log("mutations", mutations)
    fetch("https://3g2qblp5.api.sanity.io/v2021-10-21/data/mutate/production", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_SANITY}`,
      },
      body: JSON.stringify(mutations),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        if (response.transactionId) {
          setOpenDelete(true)
          handleShowProductList()
        }
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteBrandProducts = (brand, product) => {
   
    if(brand.length === 0 || product.length === 0){
      alert("Please Choose a Brand and Product")
    } else {
      
    let mutations = {
      mutations: [
        {
          delete: {
            query: `*[_type == 'productlist' && brand == '${brand}' && product == '${product}']`,
          },
        },
      ],
    }
    fetch("https://3g2qblp5.api.sanity.io/v2021-10-21/data/mutate/production", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_SANITY}`,
      },
      body: JSON.stringify(mutations),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        if (response.transactionId) {
          setOpenDelete(true)
          setOpenModal(false)
        }
      })
      .catch((err) => console.log(err))
    }

  }

  const handleDelete = () => {
    const data = async () => {
      const query = `*[_type == "productlist"]`
      const productlist_response = await client.fetch(query)
      setProductList({ productlist: productlist_response, show: false })
    }
    data()
    setOpenModal(true)
    // let mutations = {
    //   mutations: [
    //     {
    //       delete: {
    //         query: "*[_type == 'productlist']",
    //       },
    //     },
    //   ],
    // }
    // fetch("https://3g2qblp5.api.sanity.io/v2021-10-21/data/mutate/production", {
    //   method: "post",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${process.env.REACT_APP_SANITY}`,
    //   },
    //   body: JSON.stringify(mutations),
    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //     console.log(response)
    //     if (response.transactionId) {
    //       setOpenDelete(true)
    //     }
    //   })
    //   .catch((err) => console.log(err))
  }
  const handleChange = (event) => {
    Array.from(event.target.files).map(async (file) => {
      //   let text = await file.text()
      //   let result = parse(text, { header: true })
      //   console.log(result)
      readXlsxFile(file).then(async (rows) => {
        const columnNames = rows.shift()
        const objs = rows.map((row) => {
          // Map the rest of the rows into objects
          const obj = {} // Create object literal for current row
          row.forEach((cell, i) => {
            obj[columnNames[i]] = cell // Use index from current cell to get column name, add current cell to new object
          })
          return obj
        })
        console.log(objs)
        let mutations = []
        objs.map((item) => {
          let mutation = {
            _id: item.id,
            _type: "productlist",
            name: item.model,
            product: item.product,
            brand: item.brand,
            mop: String(item.mop),
            sp: String(item.sp),
            lp: String(item.lp),
            mop_incentive: String(item.mop_incentive),
            sp_incentive: String(item.sp_incentive),
            lp_incentive: String(item.lp_incentive),
            nlc:String(item.nlc)
          }
          mutations.push({ createOrReplace: mutation })
        })
        // console.log(mutations)
        // console.log(process.env.REACT_APP_SANITY)
        // mutations = [mutations[0]]

        const data = () => {
          console.log(mutations)
          fetch(
            "https://3g2qblp5.api.sanity.io/v2021-10-21/data/mutate/production",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_SANITY}`,
              },
              body: JSON.stringify({ mutations }),
            }
          )
            .then((response) => response.json())
            .then((response) => {
              if (response.transactionId) {
                setOpen(true)
              }
            })
            .catch((err) => console.log(err))
        }

        setTimeout(data, 2000)
      })
    })
  }
  const handleClear = () => {
    let upload = document.getElementById("upload")
    upload.value = ""
  }
  return (
    <>
      <div className="updatecontainer">
        <TextField
          id="upload"
          name="upload"
          type="file"
          onChange={(event) => handleChange(event)}
        />
        <Button onClick={handleClear} variant="contained" color="secondary">
          CLEAR
        </Button>
        <Button onClick={handleDelete} variant="contained" color="error">
          DELETE RECORDS
        </Button>
        <Button onClick={handleShowProductList} variant="contained">
          VIEW PRODUCTS
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={10000}
          onClose={() => setOpen(false)}
          message="Your File has been updated"
          ContentProps={{
            sx: {
              background: "green",
            },
          }}
          // action={action}
        />
        <DeleteModal
          open={openmodal}
          setOpen={setOpenModal}
          productlist={productlist}
          handleDeleteBrandProducts={handleDeleteBrandProducts}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={opendelete}
          autoHideDuration={10000}
          onClose={() => setOpenDelete(false)}
          message="Your Previous Records are Deleted. You can upload New Sheet"
          ContentProps={{
            sx: {
              background: "green",
            },
          }}
          // action={action}
        />
      </div>
      <div style={{ marginTop: "2%" }}>
        {productlist.show && <Data productlist={productlist.productlist} selectedrows={selectedrows} setSelectedRows={setSelectedRows} handleDeleteSelectedRows={handleDeleteSelectedRows}/>}
        {productlist.show && productlist.productlist.length === 0 && (
          <div>No Products Found</div>
        )}
      </div>
    </>
  )
}

export default Update
