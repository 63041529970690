import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { Autocomplete, TextField } from "@mui/material"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

export default function DeleteModal({
  open,
  setOpen,
  productlist,
  handleDeleteBrandProducts,
}) {
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [brand, setBrand] = React.useState("")
  const [product, setProduct] = React.useState("")

  let brands = []
  let products = []

  productlist.productlist.map((item) => {
    brands.push(item.brand)
  })

  productlist.productlist.map((item) => {
    products.push(item.product)
  })
  products = [...new Set(products)]
  brands = [...new Set(brands)]

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
 
          <Autocomplete
            disablePortal
            options={brands}
            value={brand}
            style={{ width: "100%", marginTop: "30px" }}
            // sx={{ width: 300 }}
            onChange={(event, item) => {
              setBrand(item)
            }}
            renderInput={(params) => (
              <TextField {...params} label="Which Brand you wish to delete?" />
            )}
          />
          <Autocomplete
            disablePortal
            options={products}
            value={product}
            style={{ width: "100%", marginTop: "30px" }}
            // sx={{ width: 300 }}
            onChange={(event, item) => {
              setProduct(item)
            }}
            renderInput={(params) => (
              <TextField {...params} label="Which Product you wish to delete?" />
            )}
          />
          <Button
            variant="outlined"
            style={{ marginTop: "5%" }}
            onClick={() => handleDeleteBrandProducts(brand, product)}
          >
            DELETE
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
